import * as React from "react";

import { cx } from "#cva.config";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
	return (
		<input
			className={cx(
				"flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-2 text-base shadow-sm transition-transform file:mr-4 file:h-full disabled:cursor-not-allowed file:rounded-sm dark:border-zinc-700/75 file:border-0 dark:bg-zinc-900/25 dark:file:bg-zinc-700 file:bg-gray-300 file:font-medium dark:placeholder:text-zinc-500 dark:text-zinc-300 file:text-sm placeholder:text-gray-400 disabled:opacity-50 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
				className,
			)}
			ref={ref}
			type={type}
			{...props}
		/>
	);
});
Input.displayName = "Input";

export { Input };
